<template>
  <v-form ref="order-detail-form" @submit.prevent="formSubmit">
    <v-row>
      <v-col cols="12" class="d-flex">
        <v-btn @click.prevent="$router.replace({ name: 'order' })" plain :disabled="isFetching">
          <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
          <div>上一頁</div>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <div class="px-5 page-title">{{ $route.meta.title }}</div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card rounded="lg" class="pa-5">
          <v-card-subtitle>
            <h3>學生資料</h3>
          </v-card-subtitle>
          <v-card-text class="my-5">
            <v-row>
              <v-col md="6" cols="12">
                <form-control inputType="string" :value="createDate" label="報名日期" :readonly="true" />
              </v-col>
            </v-row>
            <v-row>
              <v-col md="6" cols="12">
                <form-control inputType="string" v-model="userData.user_name" label="學生名稱" :readonly="true" />
              </v-col>
              <v-col md="6" cols="12">
                <form-control inputType="string" v-model="userData.email" label="電郵" :readonly="true" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card rounded="lg" class="pa-5">
          <v-card-subtitle>
            <h3>訂單內容</h3>
          </v-card-subtitle>
          <v-card-text class="my-5">
            <v-row>
              <v-col md="6" cols="12">
                <form-control inputType="string" v-model="orderData.id" label="訂單編號" :readonly="true" />
              </v-col>

              <v-col md="6" cols="12">
                <form-control
                  inputType="select"
                  v-model="courseData.course_mode"
                  label="課程類型"
                  :readonly="true"
                  :options="courseModeList"
                />
              </v-col>
              <v-col md="6" cols="12">
                <form-control inputType="string" v-model="courseData.course_name" label="課程標題" :readonly="true" />
              </v-col>
              <v-col md="6" cols="12">
                <form-control
                  :prefix="'$'"
                  inputType="string"
                  v-model="discountTotal"
                  label="總金額"
                  :readonly="true"
                />
              </v-col>
              <v-col md="6" cols="12">
                <form-control inputType="string" v-model="orderData.amount" label="人數" :readonly="true" />
              </v-col>
              <!-- :readonly="saveOrderStatus === 'cancelled' || saveOrderStatus === 'paid'" -->
              <v-col md="6" cols="12">
                <form-control
                  inputType="select"
                  v-model="orderData.order_status"
                  label="付款狀態"
                  :options="orderStatusList"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card rounded="lg" class="pa-5">
          <v-card-subtitle>
            <h3>付款紀錄</h3>
          </v-card-subtitle>
          <v-card-text class="my-5" v-if="paymentList.length > 0">
            <v-row v-for="(record, index) in paymentList" :key="index">
              <v-col :md="record.payment_method === 'fps' ? 4 : 6" cols="12">
                <form-control
                  inputType="select"
                  :value="record.payment_method"
                  label="付款方式"
                  :readonly="true"
                  :options="paymentMethodList"
                />
              </v-col>
              <v-col :md="record.payment_method === 'fps' ? 4 : 6" cols="12">
                <form-control
                  :prefix="'$'"
                  inputType="string"
                  :value="record.total"
                  label="已付金額"
                  :readonly="true"
                />
              </v-col>
              <v-col md="4" cols="12" v-if="record.payment_method === 'fps'">
                <FormButtonUpload
                  :leftXl="12"
                  :leftLg="12"
                  :leftMd="12"
                  :removeLeftPadding="true"
                  :removeRightPadding="true"
                  :showUploadFileName="false"
                  :showUploadTextField="true"
                  :removeAllPadding="true"
                  :label="'付款證明'"
                  :uploadFile.sync="paymentList[index].payment_key"
                  :readonly="saveOrderStatus === 'cancelled'"
                  :fileInputKey="`file_upload_${index}`"
                ></FormButtonUpload>
              </v-col>
            </v-row>

            <v-row v-if="remaining > 0">
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col :md="remaining > 0 ? '5' : '12'" cols="12">
                <form-control
                  class="mr-4"
                  :prefix="'$'"
                  inputType="string"
                  :value="remaining"
                  label="餘額"
                  :readonly="true"
                />
              </v-col>
              <v-col md="5" cols="12">
                <FormButtonUpload
                  :leftXl="12"
                  :leftLg="12"
                  :leftMd="12"
                  :removeLeftPadding="true"
                  :removeRightPadding="true"
                  :showUploadFileName="false"
                  :showUploadTextField="true"
                  :removeAllPadding="true"
                  :label="'付款證明'"
                  :errorText="remainingUploadRecordError"
                  :uploadFile.sync="remainingUploadRecord"
                  :fileInputKey="`file_upload_pay`"
                ></FormButtonUpload>
              </v-col>

              <v-col md="2" cols="12" class="d-flex align-center">
                <v-btn
                  @click="openPaymentDialog = true"
                  class="px-5"
                  small
                  depressed
                  color="success"
                  :disabled="isFetching || saveOrderStatus !== 'reserve_paid'"
                >
                  付款
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text class="my-5" v-else>
            <v-row>
              <v-col :md="paymentData.payment_method === 'fps' ? 4 : 6" cols="12">
                <form-control
                  inputType="select"
                  :value="paymentData.payment_method"
                  label="付款方式"
                  :readonly="true"
                  :options="paymentMethodList"
                />
              </v-col>
              <v-col :md="paymentData.payment_method === 'fps' ? 4 : 6" cols="12">
                <form-control
                  :prefix="'$'"
                  inputType="string"
                  :value="paymentData.amount"
                  label="已付金額"
                  :readonly="true"
                />
              </v-col>
              <v-col md="4" cols="12" v-if="paymentData.payment_method === 'fps'">
                <FormButtonUpload
                  :leftXl="12"
                  :leftLg="12"
                  :leftMd="12"
                  :removeLeftPadding="true"
                  :removeRightPadding="true"
                  :showUploadFileName="false"
                  :showUploadTextField="true"
                  :removeAllPadding="true"
                  :label="'付款證明'"
                  :uploadFile.sync="paymentData.payment_key"
                  :readonly="saveOrderStatus === 'cancelled'"
                ></FormButtonUpload>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-btn color="primary white--text" class="px-6" small depressed :disabled="isFetching" type="submit">
          提交
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="mt-6" v-if="$validate.DataValid(reserveInvoiceFile) || $validate.DataValid(invoiceFile)">
      <v-col cols="12">
        <v-btn
          v-if="$validate.DataValid(reserveInvoiceFile)"
          color="secondary white--text"
          class="px-6 mr-5"
          depressed
          :disabled="isFetching"
          @click="openPDF(reserveInvoiceFile)"
        >
          下載留位費收據
        </v-btn>
        <v-btn
          v-if="$validate.DataValid(invoiceFile)"
          color="secondary white--text"
          class="px-6"
          depressed
          :disabled="isFetching"
          @click="openPDF(invoiceFile)"
        >
          下載收據
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog max-width="400" v-model="openPaymentDialog" :disabled="isFetching">
      <v-card class="py-3">
        <v-card-text class="d-flex flex-column justify-center align-center">
          <v-avatar class="mt-2" icon size="50">
            <v-icon size="3rem" color="error">{{ icons.mdiAlertCircleOutline }}</v-icon>
          </v-avatar>
          <span class="text-h6 mt-2">確認已付餘額？ </span>
          <v-row class="mt-2">
            <v-col cols="6">
              <v-btn
                color="secondary"
                class="m-auto white--text form-btn"
                block
                x-large
                depressed
                :disabled="isFetching"
                @click.prevent="openPaymentDialog = false"
              >
                取消
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                color="error"
                class="m-auto white--text form-btn"
                depressed
                x-large
                :disabled="isFetching"
                @click.prevent="payRemaining"
              >
                確定
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiChevronLeft, mdiAlertCircleOutline } from '@mdi/js'
import FormControl from '@/components/form/FormControl.vue'
import FormButtonUpload from '@/components/form/FormButtonUpload.vue'

export default {
  name: 'orderDetail',
  components: {
    FormControl,
    FormButtonUpload,
  },
  setup() {
    return {
      icons: {
        mdiChevronLeft,
        mdiAlertCircleOutline,
      },
    }
  },
  data() {
    return {
      orderData: {},
      userData: {},
      courseData: {},
      paymentList: [],

      // for old data
      paymentData: {},

      remaining: 0,
      discountTotal: 0,

      saveOrderStatus: '',

      reserveInvoiceFile: '',
      invoiceFile: '',

      remainingUploadRecord: '',
      remainingUploadRecordError: '',

      openPaymentDialog: false,

      submitSuccess: false,
    }
  },
  computed: {
    ...mapState({
      isFetching: state => state.request.isFetching,
    }),

    id: function () {
      return this.$route.params.id
    },

    createDate: function () {
      return this.$func.getDateStr(this.orderData.create_date)
    },

    courseModeList: function () {
      return this.$courseModeList
    },

    paymentMethodList: function () {
      return this.$paymentMethodList
    },

    orderStatusList: function () {
      /* const disableStatus = []

      if (this.saveOrderStatus === 'unpaid') {
        disableStatus.push('paid')
        disableStatus.push('reserve_paid')
      } else if (this.saveOrderStatus === 'paid') {
        disableStatus.push('unpaid')
        disableStatus.push('reserve_paid')
      } else if (this.saveOrderStatus === 'reserve_paid') {
        disableStatus.push('unpaid')
        disableStatus.push('paid')
      } else if (this.saveOrderStatus === 'cancelled') {
        disableStatus.push('unpaid')
        disableStatus.push('paid')
        disableStatus.push('reserve_paid')
      }

      return this.$orderStatusList.map(obj => {
        return { ...obj, disabled: disableStatus.includes(obj.value) }
      }) */

      return this.$orderStatusList
    },
  },
  methods: {
    ...mapActions(['setFetching'], 'request'),
    ...mapActions(['setDialogMessage', 'setShowDialog']),

    async getOrderDetail() {
      let orderError = false
      let message = '資料不存在'

      if (!this.isFetching) {
        this.setFetching(true)

        try {
          const payload = {
            id: parseInt(this.id),
            verify_token: this.getAdminToken(),
            get_user: true,
            get_updated_course: true,
            get_payment: true,
          }

          const result = await this.$XHR.api('cms_get_order_by_id', payload)
          this.$func.log('-----Get Order Detail-----')
          this.$func.log(result)

          this.orderData = result
          this.saveOrderStatus = result.order_status
          this.userData = this.$validate.DataValid(result.user_data) ? result.user_data : {}
          this.courseData = this.$validate.DataValid(result.updated_course) ? result.updated_course : result.course_data
          if (this.$validate.DataValid(result.payment_list) && result.payment_list.length > 0) {
            this.discountTotal = result.payment_list[0].discount_subtotal
            this.paymentList = result.payment_list

            for (let i = 0; i < this.paymentList.length; i++) {
              if (this.$validate.DataValid(this.paymentList[i].invoice_file)) {
                if (this.paymentList[i].pay_reservation) {
                  this.reserveInvoiceFile = this.paymentList[i].invoice_file
                } else {
                  this.invoiceFile = this.paymentList[i].invoice_file
                }
              }

              this.remaining += parseFloat(this.paymentList[i].total)
            }

            this.remaining = this.discountTotal - this.remaining
          } else if (this.$validate.DataValid(result.payment_data)) {
            this.paymentData = result.payment_data
          }

          this.$store.dispatch('toggleLoadingPage', false)
        } catch (error) {
          this.$func.log('-----Get Order Fail-----')
          this.$func.log(error)

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            orderError = true
            message = '沒有權限'
          } else {
            orderError = true
          }
        } finally {
          this.setFetching(false)
        }

        if (orderError) {
          this.$store.dispatch('toggleAlertMessage', {
            show: true,
            message,
            type: 'error',
            refresh: false,
            redirect: 'order',
          })
        }
      }
    },

    async payRemaining() {
      if (!this.isFetching) {
        this.setFetching(true)

        try {
          const payload = {
            id: parseInt(this.id),
            verify_token: this.getAdminToken(),
            pay_remaining: parseFloat(this.remaining),
            payment_record: this.remainingUploadRecord,
          }

          const result = await this.$XHR.api('cms_pay_order_remaining', payload)
          this.$func.log('-----Pay Remaining-----')
          this.$func.log(result)

          this.setFetching(false)
          this.openPaymentDialog = false

          this.setDialogMessage({
            message: '付款成功',
            isError: false,
            returnLink: null,
          })
          this.setShowDialog(true)

          this.getOrderDetail()
        } catch (error) {
          this.$func.log('-----Pay Remaining Fail-----')
          this.$func.log(error)

          let msg = '付款失敗'

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            msg = '沒有權限'
          } else if (error.data === 'order not found') {
            msg = '找不到訂單'
          } else if (error.data === 'order cancelled') {
            msg = '訂單已取消'
          } else if (error.data === 'payment record not found') {
            msg = '找不到付款記錄'
          } else if (error.data === 'payment does not required') {
            msg = '此訂單不需付款'
          } else if (error.data === 'total not match') {
            msg = '付款金額不正確'
          }

          if (this.$validate.DataValid(msg)) {
            this.$store.dispatch('toggleAlertMessage', {
              show: true,
              message: msg,
              type: 'error',
              refresh: false,
              redirect: '',
            })
          }

          this.setFetching(false)
        }
      }
    },

    async formSubmit() {
      if (!this.isFetching) {
        this.setFetching(true)

        try {
          const payload = {
            id: parseInt(this.id),
            verify_token: this.getAdminToken(),
            order_status: this.orderData.order_status,
          }

          if (this.paymentList.length > 0) {
            const updatePayment = []

            for (let i = 0; i < this.paymentList.length; i++) {
              if (this.$validate.DataValid(this.paymentList[i].payment_key)) {
                updatePayment.push({
                  id: parseInt(this.paymentList[i].id),
                  payment_key: this.paymentList[i].payment_key,
                })
              }
            }

            if (updatePayment.length > 0) {
              payload.payment_list = updatePayment
            }
          } else {
            payload.payment_data = this.paymentData
          }

          const result = await this.$XHR.api('cms_update_order', payload)
          this.$func.log('-----Update Order-----')
          this.$func.log(result)

          this.submitSuccess = true

          this.setFetching(false)

          this.setDialogMessage({
            message: '儲存成功',
            isError: false,
            returnLink: { name: 'order' },
          })
          this.setShowDialog(true)
        } catch (error) {
          this.$func.log('-----Update Order Fail-----')
          this.$func.log(error)

          this.submitSuccess = false

          let msg = '儲存失敗'

          if (error.data === 'admin verification fail') {
            this.forceLogout()
          } else if (error.data === 'no permission') {
            msg = '沒有權限'
          } else if (error.data === 'order not found') {
            msg = '找不到訂單'
          } else if (error.data === 'order cancelled') {
            msg = '訂單已取消'
          }

          if (this.$validate.DataValid(msg)) {
            this.setDialogMessage({
              message: msg,
              isError: true,
              returnLink: null,
            })
            this.setShowDialog(true)
          }

          this.setFetching(false)
        }
      }
    },

    openPDF(file) {
      if (this.$validate.DataValid(file)) {
        window.open(this.$mediaPath + file)
      }
    },
  },
  async beforeDestroy() {
    let removeList = []
    if (this.$store.getters.removedImageList.length > 0) {
      removeList = removeList.concat(this.$store.getters.removedImageList)
    }

    if (this.submitSuccess) {
      for (let i = 0; i < this.paymentList.length; i++) {
        const index = removeList.indexOf(this.paymentList[i].payment_key)
        if (index !== -1) {
          removeList.splice(index, 1)
        }
      }
    }

    if (removeList.length > 0) {
      const removeImage = await this.$XHR.api('cms_remove_media', {
        verify_token: this.getAdminToken(),
        remove_image_list: removeList,
      })

      this.$func.log('--- remove image success ---')
      this.$func.log(removeImage)
    }

    this.$store.dispatch('resetRemovedImage', [])
  },
  async created() {
    this.$store.dispatch('toggleLoadingPage', true)
    const check = await this.checkUserData()
    if (check) {
      this.getOrderDetail()
    }
  },
}
</script>
